import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Params } from '@angular/router';
import { LoadingScreenResponse } from '@app/treatment-proposal/models/loading-screen.response';
import {
  ErrorResponse,
  GenericListResponse,
  KYCParams,
  OtpResponse,
  PaymentPlanItemResponse,
  PaymentUnsubmittedItemResponse,
  SmsJourneyPlan
} from '@core/models';
import { ApiUrlService } from '@core/services/api-url.service';
import { HelperService } from '@core/services/helper.service';
import { environment } from '@environments/environment';
import { format } from 'date-fns';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { PaymentPlanDepositStatusLabel } from '../constants/deposit.const';
import { PaymentPlanDataService } from '../data-services/payment-plan/payment-plan-data.service';
import { DepositStatus } from '../enums/deposit.enum';
import { ModuleType } from '../enums/module-type.enum';
import { PendingPlanType } from '../enums/pending-plan-type.enum';
import { UnsubmittedPlan } from '../models/common/sms-journey.interface';
import { AddressLookUpResponse } from '../models/payment/address.interface';
import { BsbValidationResponse } from '../models/payment/bsb-validation.interface';
import {
  PostRpAffordabilityRequest,
  PostRpAffordabilityResponse
} from '../models/payment/rp-affordability.interface';
import { SendSmsQueryParams } from '../models/payment/sms.interface';
import { ConfigService } from './config/config.service';
import { PlanTypeService } from './plan-type/plan-type.service';
import { UserSettingsService } from './user-settings/user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  unsubmittedPlansSubject = new BehaviorSubject<PaymentUnsubmittedItemResponse[]>([]);

  constructor(
    private httpClient: HttpClient,
    private apiUrlService: ApiUrlService,
    private helperService: HelperService,
    private configService: ConfigService,
    private paymentPlanDataService: PaymentPlanDataService,
    private planTypeService: PlanTypeService,
    private userSettingsService: UserSettingsService
  ) {}

  getPaymentPlanApproval$(): Observable<PaymentPlanItemResponse[]> {
    return this.paymentPlanDataService.getApprovalQueue$().pipe(
      map(response => {
        if (!response.errors.length) {
          const plans = response.data;
          const transformedPlans = plans.map(plan => this.transformApprovalQueueItem(plan));

          if (this.userSettingsService.isTreatmentProposalEnabled()) {
            return this.helperService.onSort(
              'deposit_expiry',
              'asc',
              transformedPlans,
              'dateTime',
              {
                dateTimeFormat: 'dd/MM/yyyy h:m:s a'
              }
            ) as any;
          }
          return transformedPlans;
        }
        return [];
      })
    );
  }

  getPaymentPlanUnsubmitted$(): Observable<PaymentUnsubmittedItemResponse[]> {
    return this.unsubmittedPlansSubject.asObservable();
  }

  getUnsubmittedPlanById(id: string): PaymentUnsubmittedItemResponse | undefined {
    const snapshot = this.unsubmittedPlansSubject.getValue();
    return snapshot.find(up => up.id === id);
  }

  updateUnsubmittedPlan(planId: string, updatedValue: PaymentUnsubmittedItemResponse): void {
    const updatedList = this.unsubmittedPlansSubject.getValue().map(unsubmittedPlan => {
      if (unsubmittedPlan.id === planId) {
        return updatedValue;
      }
      return unsubmittedPlan;
    });
    this.unsubmittedPlansSubject.next(updatedList);
  }

  deleteUnsubmittedPlan(planId: string): void {
    const updatedList = this.unsubmittedPlansSubject.getValue().filter(plan => plan.id !== planId);
    this.unsubmittedPlansSubject.next(updatedList);
  }

  fetchUnsubmittedPlans$(): Observable<PaymentUnsubmittedItemResponse[]> {
    return this.paymentPlanDataService.getPaymentPlanUnSubmitted().pipe(
      map(response => (this.helperService.checkAPIResponse(response) ? response.data : [])),
      tap(data => {
        const unsubmittedPlans = data.map(up => this.transformUnsubmittedPlan(up));
        this.unsubmittedPlansSubject.next(unsubmittedPlans);
      })
    );
  }

  fetchUnsubmittedPlans(): void {
    this.paymentPlanDataService
      .getPaymentPlanUnSubmitted(true)
      .pipe(map(response => (this.helperService.checkAPIResponse(response) ? response.data : [])))
      .subscribe(data => {
        const unsubmittedPlans = data.map(up => this.transformUnsubmittedPlan(up));
        this.unsubmittedPlansSubject.next(unsubmittedPlans);
      });
  }

  resetUnsubmittedPlans(): void {
    this.unsubmittedPlansSubject.next([]);
  }

  putUpdatePaymentPlan(id: string, body: any): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrlService.updatePaymentPlanUrl}/${id}`, body).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          data = this.helperService.getAPIDataResponse(data);
          return data;
        }
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getPDF(key: string): Observable<GenericListResponse<{ files: string }, HttpErrorResponse>> {
    return this.httpClient
      .get<GenericListResponse<{ files: string }, HttpErrorResponse>>(
        this.apiUrlService.paymentPlanSummaryUrl + `/${key}/download`
      )
      .pipe(
        filter(response => !!response?.data[0]),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  getInvoicePDF(
    key: string
  ): Observable<GenericListResponse<{ files: string }, HttpErrorResponse>> {
    return this.httpClient
      .get<GenericListResponse<{ files: string }, HttpErrorResponse>>(
        this.apiUrlService.remittanceListUrl + `/${key}/download`
      )
      .pipe(
        filter(response => !!response?.data[0]),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  deletePlan(id: string) {
    return this.httpClient.delete(this.apiUrlService.savedPlanUrl + `/${id}`).pipe(
      map((response: any) => {
        if (this.helperService.checkAPIResponse(response)) {
          this.deleteUnsubmittedPlan(id);
          return response.data;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  smsPlan(id: string, options: SendSmsQueryParams): Observable<any> {
    let params = new HttpParams();
    params = params.append('company_brand', environment.company_brand);

    if (options?.ddrSource) {
      params = params.append('ddr_source', options.ddrSource);
    }

    if (options?.check_expiry) {
      params = params.append('check_expiry', options.check_expiry);
    }

    if (options?.moduleType) {
      params = params.append('custrecord_module_type', options.moduleType);
    }

    return this.httpClient
      .get<GenericListResponse<{ message: string }, any>>(
        `${this.apiUrlService.smsSavedPlanUrl}/${id}`,
        {
          params
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('SMS unsuccessful', error);
          return throwError(error);
        })
      );
  }

  approveOrDecline(
    id: string,
    request: { action: string }
  ): Observable<GenericListResponse<{ message: string }, HttpErrorResponse>> {
    return this.httpClient
      .post<GenericListResponse<{ message: string }, HttpErrorResponse>>(
        this.apiUrlService.paymentPlanApprovalUrl + `/${id}`,
        request
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('approveOrDecline unsuccessful', error);
          return throwError(error.error);
        })
      );
  }

  escalate(body: any): Observable<any> {
    return this.httpClient.post<any>(this.apiUrlService.createSupportCaseUrl, body).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  recordAndPlanCheck(url: string, data: any): Observable<any> {
    const urlcheckRPRecordPlanExist = !url
      ? this.apiUrlService.checkRPRecordPlanExistUrl
      : this.apiUrlService.checkRPRecordnPlanExistNoLoginUrl + '/' + url;

    return this.httpClient.post<any>(urlcheckRPRecordPlanExist, data).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('createSupportCase unsuccessful', error);
        return throwError(error);
      })
    );
  }

  checkRPEligibility(urlParams: string, data: any) {
    const urlRpEligibility = urlParams
      ? this.apiUrlService.rpEligibilityNoLoginUrl + '/' + urlParams
      : this.apiUrlService.rpEligibilityUrl;

    return this.httpClient.post(urlRpEligibility, data).pipe(
      map((response: any) => {
        if (this.helperService.checkAPIResponse(response)) {
          response = this.helperService.getAPIDataResponse(response)[0];
          return response;
        } else {
          return response;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  savePlanWithoutLogin(plan: any, urlParams: any): Observable<any> {
    return this.httpClient.put(this.apiUrlService.savedPlanNoLoginUrl + '/' + urlParams, plan).pipe(
      map((response: any) => {
        const data = response?.data;
        if (data) {
          this.helperService.updateKeyValue(
            {
              ddr_plan_type_final: data.ddr_plan_type_final
            },
            'resumePlan'
          );
          return data;
        }
      })
    );
  }

  submit(plan: any, urlParams: any): Observable<any> {
    return this.httpClient
      .post(this.apiUrlService.savedPlanNoLoginUrl + '/' + urlParams, plan)
      .pipe(
        map((data: any) => {
          if (this.helperService.checkAPIResponse(data)) {
            this.helperService.updateKeyValue(
              {
                ddr_plan_type_final: this.helperService.getAPIDataResponse(data).ddr_plan_type_final
              },
              'resumePlan'
            );
            return this.helperService.getAPIDataResponse(data);
          }
        })
      );
  }

  sendSms(ddr: string, ddrSource: string): Observable<any> {
    return this.httpClient
      .get(
        this.apiUrlService.smsSavedPlanUrl +
          '/' +
          ddr +
          '?company_brand=' +
          environment.company_brand +
          '&ddr_source=' +
          ddrSource
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  fetch(id: number): Observable<any> {
    return this.httpClient.get(this.apiUrlService.savedPlanUrl + `/${id}`);
  }

  prepare(id: string) {
    sessionStorage.setItem('preparedPlan', id + '');
  }

  getResumePlanId(): Observable<any> {
    const id = parseInt(sessionStorage.getItem('preparedPlan') || '0', 10);
    return this.httpClient.get(this.apiUrlService.savedPlanUrl + `/${id}`);
  }

  unprepared() {
    sessionStorage.setItem('preparedPlan', '');
  }

  retrievePlan(params: Params): Observable<UnsubmittedPlan> {
    const appendParams = this.getRetrievePlanParams(params);
    return this.httpClient.get(this.apiUrlService.savedPlanNoLoginUrl + '/' + appendParams).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          data = this.helperService.getAPIDataResponse(data);

          sessionStorage.setItem('form_fields', JSON.stringify(data.form_fields));
          sessionStorage.setItem('user', JSON.stringify(data.provider_details));
          sessionStorage.setItem('resumePlan', JSON.stringify(data.payment_plan));
          this.userSettingsService.setCurrentProviderSettings(data.provider_details?.settings);

          return data;
        }
      })
    );
  }

  addressLookup(term: string): Observable<AddressLookUpResponse[]> {
    const requestPayload = {
      payload: [
        {
          fullAddress: term
        }
      ],
      sourceOfTruth: 'AUSOTS'
    };

    return this.httpClient.post(this.apiUrlService.addressLookupUrl, requestPayload).pipe(
      map((data: any) => {
        return data?.payload || [];
      })
    );
  }

  checkBSB(request: { bsbNumber: string }, urlParams: string): Observable<BsbValidationResponse[]> {
    const bsbUrl =
      urlParams === ''
        ? this.apiUrlService.bsbCheckUrl
        : this.apiUrlService.bsbCheckNoLoginUrl + '/' + urlParams;

    return this.httpClient.post(bsbUrl, request).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          return this.helperService.getAPIDataResponse(data);
        }
        return data;
      })
    );
  }

  verifyKYC(urlParams: string, params: KYCParams) {
    const apiLink = this.apiUrlService.verifyKYC + '/' + urlParams;
    // console.log(params.payload);
    return this.httpClient.post(apiLink, params.payload).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          // update session storage based on response
          const dataResponse = this.helperService.getAPIDataResponse(data);
          this.helperService.updateKeyValue(
            {
              custrecord_ddrip_greenid_overall_status:
                dataResponse.custrecord_ddrip_greenid_overall_status,
              custrecord_ddrip_greenid_verification_id:
                dataResponse.custrecord_ddrip_greenid_verification_id
            },
            'resumePlan'
          );

          return data;
        }
      })
    );
  }

  sendOTP(urlParams: string, params: { [key: string]: any }): Observable<OtpResponse[]> {
    // declare api endpoint with param link
    const apiLink = this.apiUrlService.sendOTPUrl + '/' + urlParams;
    return this.httpClient.post(apiLink, params).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          return data;
        }
        return;
      })
    );
  }

  resendOTP(urlParams: string, params: { [key: string]: any }): Observable<OtpResponse[]> {
    // declare api endpoint with param link
    const apiLink = this.apiUrlService.resendOTPUrl + '/' + urlParams;
    return this.httpClient.post(apiLink, params).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          return data;
        }
        return;
      })
    );
  }

  confirmOTP(urlParams: string, params: { [key: string]: any }): Observable<any> {
    const apiLink = this.apiUrlService.confirmOTPUrl + '/' + urlParams;
    return this.httpClient.post(apiLink, params).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          this.helperService.updateKeyValue(
            {
              custentity_email_verified: true
            },
            'resumePlan'
          );
          return data;
        }
      })
    );
  }

  getRetrievePaymentPlanWithoutLogin(appendParams: string) {
    return this.httpClient.get(this.apiUrlService.savedPlanNoLoginUrl + '/' + appendParams).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          data = this.helperService.getAPIDataResponse(data);
          return data;
        }
        return;
      })
    );
  }

  previewPDFNoLogin(urlParams: string, plan: SmsJourneyPlan): Observable<string> {
    let previewURL = this.apiUrlService.previewPdfUrl;

    if (urlParams !== '') {
      previewURL = this.apiUrlService.previewPDFNoLoginUrl + '/' + urlParams;
    }
    return this.httpClient
      .post<any>(previewURL, plan)
      .pipe(map(response => response.base64PdfString));
  }

  getConfig(page: string, value: string): Observable<any> {
    return this.configService.getConfigValue(page, value);
  }

  putUpdateUnsubmittedPlan(id: string, body: any): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrlService.updateSavedPlanUrl}/${id}`, body).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          data = this.helperService.getAPIDataResponse(data);
          return data;
        }
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  postCheckRpAffordability(
    body: PostRpAffordabilityRequest
  ): Observable<PostRpAffordabilityResponse[]> {
    return this.httpClient.post(this.apiUrlService.postRpAffordability, body).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          data = this.helperService.getAPIDataResponse(data);
          return data;
        }
      })
    );
  }

  retrieveProposalLoaderDetails(
    ddrDraftId: string,
    providerName: string
  ): Observable<LoadingScreenResponse> {
    return this.httpClient
      .get<GenericListResponse<LoadingScreenResponse, ErrorResponse>>(
        `${this.apiUrlService.savedPlanNoLoginUrl}/proposal-loading-screen/${ddrDraftId}?providerName=${providerName}`
      )
      .pipe(
        map(data => {
          return data.data[0];
        })
      );
  }

  transformToApiModel(
    pendingPlanModel: PaymentUnsubmittedItemResponse
  ): PaymentUnsubmittedItemResponse {
    if (!pendingPlanModel.json_transformed) {
      return pendingPlanModel;
    }

    if (pendingPlanModel.custrecord_module_type === PendingPlanType.Amendment) {
      const formattedDate = format(
        new Date(pendingPlanModel.json_transformed.start_date),
        'dd/MM/yyyy'
      );
      pendingPlanModel.json_transformed.start_date = formattedDate;
    }

    return pendingPlanModel;
  }

  private getRetrievePlanParams(params: Params): string {
    let appendParams = params['ddrDraftId'] + '?expires=' + params['expires'];

    if (params['payload_encrypt']) {
      appendParams = appendParams + '&payload_encrypt=' + params['payload_encrypt'];
    }

    if (params['source']) {
      appendParams = appendParams + '&source=' + params['source'];
    }

    appendParams = appendParams + '&signature=' + params['signature'];

    return appendParams;
  }

  private transformUnsubmittedPlan(
    unsubmittedRequest: PaymentUnsubmittedItemResponse
  ): PaymentUnsubmittedItemResponse {
    if (unsubmittedRequest.custrecord_module_type === ModuleType.Proposal) {
      unsubmittedRequest.$$initialPlanType =
        this.planTypeService.getInitialPlanTypeOnViewEditPendingPlan(
          unsubmittedRequest.json_transformed.plan_type,
          unsubmittedRequest.json_transformed.custrecord_ddrip_proactive
        );
      unsubmittedRequest.$$depositReleaseDate =
        unsubmittedRequest.deposit_status === DepositStatus.PRACTICE_TO_PROCESS
          ? 'N/A'
          : unsubmittedRequest.deposit_expiry;
      unsubmittedRequest.$$startDate = this.getProposalStartDate(unsubmittedRequest);
    } else {
      unsubmittedRequest.$$startDate = unsubmittedRequest.json_transformed?.start_date;
    }

    return unsubmittedRequest;
  }

  private transformApprovalQueueItem(plan: PaymentPlanItemResponse): PaymentPlanItemResponse {
    const depositStatusToDisplay = [DepositStatus.PENDING, DepositStatus.PRACTICE_TO_PROCESS];

    return {
      ...plan,
      $$depositReleaseDate:
        plan.deposit_status === DepositStatus.PRACTICE_TO_PROCESS ? 'N/A' : plan.deposit_expiry,
      $$depositOrPifStatusLabel: depositStatusToDisplay.includes(
        plan.deposit_status as DepositStatus
      )
        ? PaymentPlanDepositStatusLabel.get(plan.deposit_status as DepositStatus) || ''
        : ''
    };
  }

  private getProposalStartDate(proposal: PaymentUnsubmittedItemResponse): string {
    const soonestDateObject = proposal.json_transformed.treatment_options.reduce(
      (earliest: any, current: any) => {
        return this.helperService.parseDate(current.start_date, 'dd/MM/yyyy') <
          this.helperService.parseDate(earliest.start_date, 'dd/MM/yyyy')
          ? current
          : earliest;
      }
    );
    return soonestDateObject.start_date;
  }
}
