<sliqpay-header
  [pageTitle]="'page_title' | lang : LANG_CONFIG_KEY | async"
  class="page-header-sticky"
>
  <div class="create-btn-container">
    <sliqpay-create-plan-dropdown
      class="mr-2"
      [moduleType]="ModuleType.Proposal"
      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL"
    ></sliqpay-create-plan-dropdown>

    <sliqpay-create-plan-dropdown></sliqpay-create-plan-dropdown>
  </div>
</sliqpay-header>

<div class="page-content">
  <div class="bg-white rounded">
    <sliqpay-payments-menu></sliqpay-payments-menu>

    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="filter-bar">
              <sliqpay-list-search-widget
                [config]="(searchWidgetConfig$ | async) ?? []"
                (searchValue)="onSearchValueChange($event)"
                (dateChange)="onDateChange($event)"
              ></sliqpay-list-search-widget>

              <div class="ml-lg-3 mb-3">
                <div class="mt-md-2">
                  <sliqpay-toggle-switch
                    [(ngModel)]="includeCompletedPlans"
                    (ngModelChange)="fetchAllPlans()"
                    [config]="{
                      label: 'Include Completed Plans',
                      labelPosition: 'after',
                      isDynamicContent: false
                    }"
                  ></sliqpay-toggle-switch>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="paymentPlanSummaryFilter$ | async">
          <div class="col-12">
            <div
              class="table-responsive all-plans-table"
              [class.drop-down-opened]="dropdownChanges"
            >
              <table class="table">
                <thead>
                  <tr>
                    <ng-container *ngFor="let column of tableColumns">
                      <th
                        scope="col"
                        [sliqpaySortable]="column.sortKey || ''"
                        [ngClass]="column.customClass"
                        [type]="column.type || ''"
                        (sort)="onSort($event)"
                      >
                        <span
                          class="sorting"
                          [innerHTML]="
                            column.langConfigKey
                              ? (column.langConfigKey | lang : LANG_CONFIG_KEY | async)
                              : column.name
                          "
                        ></span>
                      </th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="isLoading(); else listItems">
                    <tr *ngFor="let i of [].constructor(10)">
                      <td *ngFor="let columns of [].constructor(tableColumns.length)">
                        <ngx-skeleton-loader
                          [theme]="{ 'border-radius': '4px', height: '24px', width: '100%' }"
                          count="1"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template #listItems>
                    <tr
                      *ngFor="
                        let paymentPlan of (paymentPlanSummaryFilter$ | async) || []
                          | searchFilter : searchData
                          | dateFilter : searchByDate
                          | paginate : { itemsPerPage: itemPerPage, currentPage: page }
                      "
                    >
                      <td>{{ paymentPlan.id }}</td>
                      <td>{{ paymentPlan.portal_plan_type_label }}</td>
                      <td>
                        <div class="show-hide-truncate" title="{{ paymentPlan.doctor_name }}">
                          {{ paymentPlan.doctor_name }}
                        </div>
                      </td>
                      <td>{{ paymentPlan.patient_name }}</td>
                      <td>{{ paymentPlan.practice_patient_id_no }}</td>
                      <td>{{ paymentPlan?.responsible_party_name }}</td>
                      <td>${{ paymentPlan?.total | number : '1.2-2' }}</td>
                      <td
                        [sliqpayProviderConfigFeatureFlag]="
                          ProviderConfigFeature.TREATMENT_PROPOSAL
                        "
                      >
                        <ng-container *ngIf="paymentPlan?.$$isDepositPaid; else displayAsText">
                          <a
                            href="javascript:void(0)"
                            class="text-primary"
                            (click)="previewDepositInvoice(paymentPlan?.ddrId)"
                            >{{ paymentPlan.$$depositOrPifStatusLabel }}</a
                          >
                        </ng-container>
                        <ng-template #displayAsText>
                          {{ paymentPlan.$$depositOrPifStatusLabel }}
                        </ng-template>
                      </td>
                      <td>
                        {{ '$' + (paymentPlan?.providerResidual || '0' | number : '1.2-2') }}
                      </td>
                      <td class="text-ellipsis" (click)="comment(paymentPlan)">
                        <span
                          [innerHTML]="
                            paymentPlan.custrecord_mfa_ddr_provider_comments | decodeLineBreak
                          "
                        ></span>
                      </td>
                      <td
                        id="treatmentOptionName"
                        [sliqpayProviderConfigFeatureFlag]="
                          ProviderConfigFeature.TREATMENT_PROPOSAL
                        "
                      >
                        {{ paymentPlan.treatment }}
                      </td>
                      <td>
                        <div class="d-flex justify-content-between">
                          <span
                            class="btn btn-status text-nowrap"
                            sliqpayStatusBtnClass
                            [value]="paymentPlan?.ddr_status_label"
                            >{{ paymentPlan?.ddr_status_label }}</span
                          >
                          <sliqpay-payments-action-menu
                            [plan]="paymentPlan"
                            (planChange)="planChanged($event)"
                            (dropdownChanges)="dropdownChanges = $event"
                          ></sliqpay-payments-action-menu>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <sliqpay-empty-screen
      iconClass="icon-empty-screen"
      text="No plans here yet"
      *ngIf="
        !isLoading() &&
        ((paymentPlanSummaryFilter$ | async) === null ||
          (paymentPlanSummaryFilter$ | async)?.length === 0)
      "
    ></sliqpay-empty-screen>
  </div>

  <sliqpay-pagination
    [(page)]="page"
    [(itemPerPage)]="itemPerPage"
    *ngIf="!isLoading() && (paymentPlanSummaryFilter$ | async)?.length"
  ></sliqpay-pagination>
</div>
