import { Injectable, Signal, signal } from '@angular/core';
import {
  ProposalTemplateDataService,
  SaveTemplateProposalResponse
} from '@app/core/data-services/proposal-template/proposal-template-data.service';
import { GetProposalTemplateResponse } from '@app/templates/models/get-proposal-template.response';
import { ProposalTextSection, SaveProposalTemplateRequest } from '@app/templates/models/save-proposal-template.request';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ImageService } from '../../../core/services/image/image.service';
import { NotificationService } from '../../../core/services/notification.service';

@Injectable()
export class ProposalTemplateService {
  private proposalTemplateSubject = new BehaviorSubject<GetProposalTemplateResponse>({
    id: '',
    customrecord_templateproposal_txtsection: [],
    custrecord_asset_footer: '',
    custrecord_asset_header: '',
    custrecord_asset_logo: '',
    custrecord_introtxt_printout: '',
    custrecord_introtxt_sms: '',
    custrecord_practice_notes: ''
  });
  private isProposalTemplateLoadedSignal = signal(false);

  constructor(
    private proposalTemplateDataService: ProposalTemplateDataService,
    private imageService: ImageService,
    private notifService: NotificationService
  ) {}

  initProposalTemplate$(config: {
    showLoadingBar: boolean;
    refresh?: boolean;
  }): Observable<boolean> {
    if (this.isProposalTemplateLoadedSignal() && !config.refresh) {
      return of(true);
    }

    return this.proposalTemplateDataService.getProposalTemplate$(config).pipe(
      switchMap(response => {
        if (response.data[0]) {
          return of(response.data[0]);
        }
        return this.getDefaultProposalTemplate$();
      }),
      tap(data => {
        this.proposalTemplateSubject.next(data);
        this.isProposalTemplateLoadedSignal.set(true);
      }),
      map(() => true)
    );
  }

  setProposalTemplateLoadedState(isLoaded: boolean): void {
    this.isProposalTemplateLoadedSignal.set(isLoaded);
  }

  isProposalTemplatesLoadedSignal(): Signal<boolean> {
    return this.isProposalTemplateLoadedSignal;
  }

  getProposalTemplate$(): Observable<GetProposalTemplateResponse> {
    return this.proposalTemplateSubject.asObservable();
  }

  getProposalTemplate(): GetProposalTemplateResponse {
    return this.proposalTemplateSubject.getValue();
  }

  upsertProposalTemplate(
    body: SaveProposalTemplateRequest
  ): Observable<SaveTemplateProposalResponse> {
    return this.proposalTemplateDataService.saveProposalTemplate(body).pipe(
      map(response => {
        return {
          templateProposalId: response.data[0].templateProposalId,
          message: response.data[0].message
        };
      }),
      tap(res => {
        this.notifService.success(res.message, 'Saved');
      })
    );
  }

  getPracticeDocumentIds(): string[] {
    const templateSnapshot = this.proposalTemplateSubject.getValue();
    const practiceDocuments = templateSnapshot.customrecord_templateproposal_txtsection;
    return practiceDocuments.map(doc => doc.id);
  }

  getPracticeDocuments$(): Observable<ProposalTextSection[]> {
    return this.proposalTemplateSubject.pipe(map(template => template.customrecord_templateproposal_txtsection))
  }

  enquireForPremium(): Observable<{
    message: string;
  }> {
    return this.proposalTemplateDataService.enquireForPremium().pipe(
      tap(res => {
        this.notifService.success(res.message, 'Enquire Sent');
      })
    );
  }

  private getDefaultProposalTemplate$(): Observable<GetProposalTemplateResponse> {
    return forkJoin([
      this.imageService.getImageAsBase64(),
      this.getDefaultProposalProviderLogo$()
    ]).pipe(
      map(([defaultBanner, defaultLogo]) => ({
        id: '',
        customrecord_templateproposal_txtsection: [],
        custrecord_asset_footer: defaultBanner,
        custrecord_asset_header: defaultBanner,
        custrecord_asset_logo: defaultLogo,
        custrecord_introtxt_printout: '',
        custrecord_introtxt_sms: '',
        custrecord_practice_notes: ''
      }))
    );
  }

  private getDefaultProposalProviderLogo$(): Observable<string> {
    return this.imageService.getImageAsBase64(
      `assets/images/${environment.client}/default-proposal-logo.png`
    );
  }
}
