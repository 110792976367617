import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { ProviderConfigFeature } from '@app/core/directives/provider-config-feature-flag/provider-config-feature.enum';
import { DepositStatus } from '@app/core/enums/deposit.enum';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { PaymentPlanItemResponse } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { PaymentService } from '@app/core/services/payment.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { DateSearchRequest, SearchRequest } from '@app/shared/interfaces/search-request.interface';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PaymentPlansColumn } from '../models/payment-plans-column.model';
import { ApprovalQueueTableColumns } from './approval-plans-columns.data';
import { ApprovalPlansService } from './approval-plans.service';

@Component({
  selector: 'sliqpay-approval-plans',
  templateUrl: './approval-plans.component.html',
  styleUrls: ['./approval-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ApprovalPlansService]
})
export class ApprovalPlansComponent implements OnInit, OnDestroy {
  readonly LANG_CONFIG_KEY = 'approval_queue';

  ModuleType = ModuleType;
  ProviderConfigFeature = ProviderConfigFeature;
  DepositStatus = DepositStatus;

  tableColumns: PaymentPlansColumn[] = [];
  isLoading = signal(false);
  itemPerPage = environment.itemPerPage;
  page = 0;
  searchData: SearchRequest = {};
  dateSearchData: DateSearchRequest = {};

  trackByFn = (index: number, plan: PaymentPlanItemResponse) => plan.id;

  paymentPlanApproval$ = new BehaviorSubject<PaymentPlanItemResponse[] | null>(null);
  paymentPlanApprovalFiltered$ = new BehaviorSubject<PaymentPlanItemResponse[] | null>(null);
  loading$ = new BehaviorSubject(false);
  private unsubscribe$ = new Subject<boolean>();

  // Config
  approveBtnConfig$!: Observable<AppButton>;
  declineBtnConfig$!: Observable<AppButton>;
  createPlanBtnConfig$!: Observable<AppButton>;
  searchWidgetConfig$!: Observable<SearchConfig[]>;

  constructor(
    private paymentService: PaymentService,
    private modal: NgbModal,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private approvalPlansService: ApprovalPlansService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tableColumns = ApprovalQueueTableColumns.filter((col) => col.visible ?? true);
    this.setConfig();
    this.getPaymentApproval();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPaymentApproval() {
    this.isLoading.set(true);
    this.paymentService
      .getPaymentPlanApproval$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(approvalQueue => {
        this.isLoading.set(false);
        this.paymentPlanApproval$.next(approvalQueue);
        this.paymentPlanApprovalFiltered$.next(approvalQueue);
      });
  }

  searchFilter(event: SearchRequest) {
    this.page = 0;
    this.searchData = event;
  }

  onDateFilterChange(event: { from: Date; to: Date }) {
    this.dateSearchData = {
      from: event.from,
      to: event.to,
      searchColumn: 'start_date'
    };
  }

  approve(plan: PaymentPlanItemResponse) {
    this.loading$.next(true);
    const modalRef = this.modal.open(ModalComponent, {
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      title: 'Approve',
      content: 'Are you sure you wish to approve this payment plan?',
      buttons: [
        {
          text: 'Cancel',
          class: 'btn-grey mr-1 w-170',
          value: 'false'
        },
        {
          text: 'Yes, Approve',
          class: 'btn-primary ml-1 w-170',
          value: 'approve'
        }
      ]
    };
    modalRef.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (value === 'approve') {
        if (plan.failedIdVerification === 'Yes') {
          this.approveFailedIdVerification(plan);
        } else {
          this.callApproveOrDeclineAPI(plan, value);
        }
      } else {
        this.loading$.next(false);
      }
    });
  }

  decline(plan: PaymentPlanItemResponse) {
    this.loading$.next(true);
    const modalDecline = this.modal.open(ModalComponent, {
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });
    modalDecline.componentInstance.data = {
      title: 'Ineligible',
      content: 'Are you sure you wish to mark as Ineligible?',
      buttons: [
        {
          text: 'Cancel',
          class: 'btn-grey mr-1 w-170',
          value: 'false'
        },
        {
          text: 'Yes, Ineligible',
          class: 'btn-primary ml-1 w-170',
          value: 'decline'
        }
      ]
    };

    modalDecline.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (value === 'decline') {
        this.paymentService
          .approveOrDecline(plan.ddrId, { action: value })
          .pipe(
            filter(response => !!response.data),
            takeUntil(this.unsubscribe$)
          )
          .subscribe(response => {
            this.loading$.next(false);
            this.toastrService.success(response.data[0].message, 'Ineligible');
            this.paymentPlanApprovalFiltered$.next(
              this.paymentPlanApprovalFiltered$.value?.filter(t => t.id !== plan.id) || null
            );
          });
      } else {
        this.loading$.next(false);
      }
    });
  }

  onSort({ column, direction, type }: any) {
    let sortedItems;
    if (type === 'datetime') {
      sortedItems = this.helperService.onSort(
        column,
        direction,
        this.paymentPlanApproval$?.value,
        type,
        {
          dateTimeFormat: 'dd/MM/yyyy h:m:s a'
        }
      );
    } else {
      sortedItems = this.helperService.onSort(
        column,
        direction,
        this.paymentPlanApproval$?.value,
        type
      );
    }
    this.paymentPlanApprovalFiltered$.next(sortedItems);
  }

  callApproveOrDeclineAPI(plan: PaymentPlanItemResponse, value: string) {
    this.paymentService
      .approveOrDecline(plan.ddrId, { action: value })
      .pipe(filter(response => !!response))
      .subscribe(response => {
        this.loading$.next(false);
        this.toastrService.success(response.data[0].message, 'Approved');
        this.paymentPlanApprovalFiltered$.next(
          this.paymentPlanApprovalFiltered$.value?.filter(t => t.id !== plan.id) || null
        );
      });
  }

  approveFailedIdVerification(plan: PaymentPlanItemResponse) {
    const modalRef = this.modal.open(ModalComponent, {
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      title: 'Approve',
      content:
        "By approving this plan you are confirming that you have successfully verified the Responsible Party's identification and wish to proceed with this " +
        environment.client +
        ' Connect Plan.',
      buttons: [
        {
          text: 'Cancel',
          class: 'btn-grey mr-1 w-170',
          value: 'false'
        },
        {
          text: 'Yes, Approve',
          class: 'btn-primary ml-1 w-170',
          value: 'approve'
        }
      ]
    };
    modalRef.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (value === 'approve') {
        this.callApproveOrDeclineAPI(plan, value);
      } else {
        this.loading$.next(false);
      }
    });
  }

  goToCreatePlanPage(): void {
    this.router.navigate(['provider-journey/create-plan']);
  }

  private setConfig(): void {
    this.approveBtnConfig$ = this.approvalPlansService.getApproveBtnConfig();
    this.declineBtnConfig$ = this.approvalPlansService.getDeclineBtnConfig();
    this.createPlanBtnConfig$ = this.approvalPlansService.getCreatePlanBtnConfig();
    this.searchWidgetConfig$ = this.approvalPlansService.getSearchWidgetConfig();
  }
}
