import { UserSettingsService } from "@app/core/services/user-settings/user-settings.service";
import { PaymentPlansColumn } from "@app/payments/models/payment-plans-column.model";

export const AllPlansTableColumns: PaymentPlansColumn[] = [
  {
    sortKey: 'id',
    langConfigKey: 'ddr_no'
  },
  {
    sortKey: 'portal_plan_type_label',
    langConfigKey: 'plan_type'
  },
  {
    sortKey: 'doctor_name',
    langConfigKey: 'doctor_name'
  },
  {
    sortKey: 'patient_name',
    langConfigKey: 'patient_name'
  },
  {
    sortKey: 'practice_patient_id_no',
    langConfigKey: 'patient_id'
  },
  {
    sortKey: 'responsible_party_name',
    langConfigKey: 'rp_name'
  },
  {
    sortKey: 'total',
    langConfigKey: 'plan_amount',
    type: 'number'
  },
  {
    sortKey: '$$depositOrPifStatusLabel',
    langConfigKey: 'deposit_status',
    visible: new UserSettingsService().isTreatmentProposalEnabled()
  },
  {
    sortKey: '$$providerResidualSortingValue',
    langConfigKey: 'provider_closing_balance'
  },
  {
    sortKey: 'custrecord_mfa_ddr_provider_comments',
    langConfigKey: 'provider_comments'
  },
  {
    sortKey: 'treatment',
    langConfigKey: 'treatment',
    customClass: 'col-wide',
    visible: new UserSettingsService().isTreatmentProposalEnabled()
  },
  {
    sortKey: 'ddr_status_label',
    langConfigKey: 'status'
  }
];
