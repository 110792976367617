<ng-container *ngIf="config">
  <mat-slide-toggle
    [(ngModel)]="value"
    (change)="onCheckedChange($event)"
    [labelPosition]="config.labelPosition"
    [disabled]="disabled"
    class="sliqpay-toggle-switch"
    [ngClass]="{ 'hide-switch': hideSwitch, 'sliqpay-toggle-switch--sm': config.size === 'sm' }"
  >
    <ng-container *ngIf="config.isDynamicContent; else asLabel">
      <ng-content></ng-content>
    </ng-container>
    <ng-template #asLabel>{{ config.label }}</ng-template>
  </mat-slide-toggle>
</ng-container>
