import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SearchType } from '@app/shared/enums/search-type.enum';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'sliqpay-list-search-widget',
  templateUrl: './list-search-widget.component.html',
  styleUrls: ['./list-search-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListSearchWidgetComponent implements OnInit, OnDestroy {
  LSW_CONFIG_KEY = 'list_search_widget_component';

  @Input() config!: SearchConfig[];
  @Output() searchValue = new EventEmitter();
  @Output() dateChange = new EventEmitter();

  searchForm!: UntypedFormGroup;
  searchTypes = SearchType;
  trackByFn = (index: number, item: SearchConfig) => item.name;
  private unsubscribe$ = new Subject();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    const formControls = this.config.reduce((configs, confItem) => {
      configs[confItem.name] = null;
      return configs;
    }, {} as { [key: string]: any });

    this.searchForm = this.fb.group(formControls);
    this.searchForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (this.searchForm.valid) {
        this.searchValue.emit(data);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSearchQueryChange(val: any): void {
    this.searchForm.patchValue({ query: val });
  }

  onDateChange(value: { from: Date; to: Date }): void {
    this.dateChange.emit(value);
  }
}
