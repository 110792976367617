<form [formGroup]="payInFullForm">
  <div class="row">
    <div class="col-md-3">
      <sliqpay-currency-input
        formControlName="fullPaymentTotalTreatmentCost"
        [config]="totalTreatmentCostConfig$ | async"
      >
      </sliqpay-currency-input>
    </div>
    <div class="icon-ctnr">
      <i class="uil uil-minus"></i>
    </div>
    <div class="col-md-3">
      <sliqpay-dynamic-discount
        label="Pay In Full Discount"
        [amountToDiscount]="pifTotalTreatmentCost?.value"
        [discountData]="{
          discountAmount: pifDiscount?.value,
          discountAsPercentage: pifDiscountAsPercentage?.value,
          discountType: pifDiscountType?.value
        }"
        [disableFields]="pifDiscount?.disabled || false"
        (discountDataChange)="pifDiscountChanged($event)"
      ></sliqpay-dynamic-discount>
    </div>
    <div class="icon-ctnr">
      <i class="uil uil-minus"></i>
    </div>
    <div class="col-md-3">
      <sliqpay-dynamic-discount
        label="Other Discount"
        [amountToDiscount]="pifTotalTreatmentCost?.value"
        [discountData]="{
          discountAmount: pifOtherDiscount?.value,
          discountAsPercentage: pifOtherDiscountAsPercentage?.value,
          discountType: pifOtherDiscountType?.value
        }"
        [disableFields]="pifDiscount?.disabled || false"
        (discountDataChange)="pifOtherDiscountChanged($event)"
      ></sliqpay-dynamic-discount>
    </div>
    <div class="icon-ctnr">
      <span class="icon-equals"></span>
    </div>
    <div class="col-md-3 payment-total-ctnr">
      <sliqpay-currency-input
        formControlName="fullPaymentNetTotal"
        [config]="paymentPlanTotalConfig$ | async"
      >
      </sliqpay-currency-input>
      <div class="invalid-feedback d-block" *ngIf="pifNetAmount?.errors">
        <div *ngIf="pifNetAmount?.hasError('min')">
          {{ pifTotalAmountErrMsg }}
        </div>
        <div *ngIf="pifNetAmount?.hasError('max')">
          {{ maxPifAmountErrorMsg }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <sliqpay-dropdown
        [config]="pifPaymentOptionsDdConfig$ | async"
        [items]="pifPaymentOptions"
        formControlName="fullPaymentPaymentPreference"
      ></sliqpay-dropdown>
    </div>
    <div class="col-md-9">
      <sliqpay-input
        [config]="pifDiscountReasonConfig$ | async"
        formControlName="fullPaymentDiscountReason"
      ></sliqpay-input>
    </div>
  </div>
</form>
