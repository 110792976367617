import { Injectable } from '@angular/core';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { DdrPlanType } from '@app/core/models/common/form-fields.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { PlanTypeService } from '@app/core/services/plan-type/plan-type.service';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { Observable, of } from 'rxjs';

@Injectable()
export class CreatePlanDropdownService {
  private CONFIG_KEY = 'create_plan_dropdown';

  constructor(private configService: ConfigService, private planTypeService: PlanTypeService) {}

  getCreateDdConfig(moduleType: ModuleType): Observable<AppDropdown> {
    switch (moduleType) {
      case ModuleType.Plan: {
        return this.getCreatePlanDdConfig();
      }
      case ModuleType.Proposal: {
        return this.getCreateProposalDdConfig();
      }
      default: {
        return this.getCreatePlanDdConfig();
      }
    }
  }

  getPlanTypes(): Observable<DdrPlanType[]> {
    return of(this.planTypeService.getPlanTypesWithAccess());
  }

  private getCreatePlanDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'create_plan_dd');
  }

  private getCreateProposalDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'create_proposal_dd');
  }
}
