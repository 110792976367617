import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ForgotPasswordRequest,
  GenericListResponse
} from '@core/models';
import { ApiUrlService } from '@core/services/api-url.service';
import { HelperService } from '@core/services/helper.service';
import { environment } from '@environments/environment';
import { UserSettingsService } from '../user-settings/user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public refFlag = false;

  private userSettingsService = inject(UserSettingsService);

  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService,
    private apiUrlService: ApiUrlService,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {}

  login(request: { email: string; password: string }): Observable<any> {
    const body = {
      ...request,
      company_brand: environment.company_brand,
      device_info: [this.deviceService.getDeviceInfo()]
    };

    return this.httpClient.post<any>(`${this.apiUrlService.loginUrl}`, body, { observe: 'response' }).pipe(
      filter((response) => {
        return !!response;
      }),
      map((response) => {
        this.setAuth(response.headers, response.body.data[0]);
        return response.body.data[0];
      })
    );
  }

  logout() {
    return this.httpClient.post(`${this.apiUrlService.logoutUrl}`, {}).pipe(
      catchError((error: HttpErrorResponse) => {
        sessionStorage.clear();
        this.router.navigate(['login']);
        return throwError(error);
      })
    );
  }

  forgotPassword(request: ForgotPasswordRequest) {
    return this.httpClient.post(`${this.apiUrlService.forgotPasswordUrl}`, {
      ...request,
      company_brand: environment.company_brand
    });
  }

  getProviderSet() {
    return sessionStorage.getItem('provider') !== null && sessionStorage.getItem('provider') !== '';
  }

  getCurrentProvider() {
    const user = this.currentUser();
    return user.providers.find((provider: any) => provider.name === user.name)?.id || false;
  }

  changePassword(
    request: ChangePasswordRequest
  ): Observable<GenericListResponse<ChangePasswordResponse, any>> {
    return this.httpClient.post<GenericListResponse<any, any>>(
      `${this.apiUrlService.changePasswordUrl}`,
      request
    );
  }

  fetchFields() {
    return this.httpClient.get(this.apiUrlService.formFieldsUrl).pipe(
      map((data: any) => {
        sessionStorage.removeItem('form_fields');
        if (this.helperService.checkAPIResponse(data)) {
          data = this.helperService.getAPIDataResponse(data)[0];
          sessionStorage.setItem('form_fields', JSON.stringify(data.values));
          return data.values;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('fetchFields unsuccessful', error);
        return throwError(error);
      })
    );
  }

  getRulesJsonFromAPI() {
    return this.httpClient.get(this.apiUrlService.rulesJsonUrl).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(error => {
        console.error('getRulesJsonFromAPI unsuccessful', error);
        return throwError(error);
      })
    );
  }

  async setRulesJson(router: Router) {
    if (environment.apimKey !== '') {
      // Non local
      this.getRulesJsonFromAPI().subscribe(data => {
        sessionStorage.setItem('rules_json', JSON.stringify(data));
        this.maintenanceRerouting(router);
        return data;
      });
    } else {
      // Local
      sessionStorage.setItem('rules_json', JSON.stringify(environment.rules_json));
      this.maintenanceRerouting(router);
    }
  }

  getPDF(fileName: string) {
    const body = {
      fileName
    };
    return this.httpClient.post(this.apiUrlService.getPdfUrl, body).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          data = this.helperService.getAPIDataResponse(data)[0];
          return data;
        }
      }),
      catchError(error => {
        return error;
      })
    );
  }

  public getCurrentToken() {
    return sessionStorage.getItem('auth');
  }

  public setProvider(id: string) {
    return this.httpClient
      .post<any>(`${this.apiUrlService.setProviderUrl}`, { 'provider-id': id })
      .pipe(
        map(response => {
          sessionStorage.setItem('provider', response.data[0]['providerId']);
          sessionStorage.setItem('user', JSON.stringify(response.data[0]));
          this.userSettingsService.setCurrentProviderSettings(response.data[0]?.settings);
          return response;
        })
      );
  }

  public currentUser() {
    return sessionStorage.getItem('user') !== null && sessionStorage.getItem('form_fields') !== ''
      ? this.helperService.getObjUser()
      : false;
  }

  public canModifyData() {
    const permissions = this.currentUser().permissions;

    return permissions['/settings'];
  }

  public getRulesJson() {
    if (environment.apimKey !== '') {
      // Non local
      return JSON.parse(sessionStorage.getItem('rules_json') || '');
    } else {
      // Local
      return environment.rules_json;
    }
  }

  public getProductRules(key: string) {
    try {
      return this.getRulesJson().product.portal[environment.company_brand.toLowerCase()][key];
    } catch (error) {}
  }

  public maintenanceRerouting(router: any) {
    // Detect and show maintenance page
    if (
      this.getGlobalRules('maintenance_mode') === true ||
      this.getProductRules('maintenance_mode') === true
    ) {
      this.router.navigate(['/maintenance']);
    } else {
      // avoid loop navigation
      if (router.url.includes('maintenance')) {
        router.navigate(['/login']);
      }
    }
  }

  public getGlobalRules(key: string) {
    try {
      return this.getRulesJson().global[key];
    } catch (error) {}
  }

  private setAuth(dataHeaders: any, dataBody: any) {
    this.setAuthHeader(dataHeaders);
    sessionStorage.setItem('auth_expires', parseInt(dataBody.expiresIn, 10).toString());
    this.setAuthBody(dataBody);
  }

  private setAuthHeader(dataHeaders: any) {
    sessionStorage.setItem('auth', dataHeaders.get('accesstoken'));
    sessionStorage.setItem('refreshtoken', dataHeaders.get('refreshtoken'));
  }

  private setAuthBody(dataBody: any) {
    sessionStorage.setItem('user', JSON.stringify(dataBody));
    sessionStorage.setItem('providers', JSON.stringify(dataBody.providers));
  }
}
