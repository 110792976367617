import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { ModalComponent } from '@core/components/modal/modal.component';
import { ErrorResponse, GenericListResponse } from '@core/models';
import { HelperService } from '@core/services/helper.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, take } from 'rxjs/operators';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  token: string | null = null;

  constructor(
    private helperService: HelperService,
    private router: Router,
    private modal: NgbModal,
    private notificationService: NotificationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.apimKey !== '') {
      request = request.clone({
        headers: request.headers.set('Ocp-Apim-Subscription-Key', environment.apimKey)
      });

      if (this.allApimUrls(request)) {
        if (environment.payload_encrypt === 'true') {
          request = request.clone({
            body: this.helperService.aesEncode(JSON.stringify(request.body)),
            responseType: 'text'
          });

          if (!['GET', 'DELETE'].includes(request.method)) {
            const requestUrl = new URL(request.url);
            requestUrl.searchParams.set('payload_encrypt', environment.payload_encrypt);
            request = request.clone({ url: requestUrl.toString() });
          }
        }

        if (['GET', 'DELETE'].includes(request.method)) {
          const requestUrl = new URL(request.url);
          if (!request.url.includes('payload_encrypt')) {
            requestUrl.searchParams.set('payload_encrypt', environment.payload_encrypt);
          }
          request = request.clone({ url: requestUrl.toString() });
        }
      }
    }

    this.token = request.url.includes(environment.api + '/refresh')
      ? sessionStorage.getItem('refreshtoken')
      : sessionStorage.getItem('auth');

    if (request.url.includes(environment.api)) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.token),
        withCredentials: true
      });
    } else {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.token)
      });

      if (request.url.includes(environment.harmonyBaseURL)) {
        request = request.clone({
          headers: request.headers.set(
            'Authorization',
            'Basic ' + btoa('payvantage_testuser' + ':' + 'UoNaqz18Hqwnf9tXmvmdC7N27EIa4gKa')
          )
        });
      }
      if (request.url.includes(environment.rules_json_url)) {
        // dont add header
        request = request.clone({
          headers: request.headers.delete('Authorization')
        });
      }
    }

    request = request.clone({
      headers: request.headers
        .set('company-brand', environment.company_brand)
        .set('provider-id', sessionStorage.getItem('provider') || '')
        .set('Cache-Control', 'no-cache')
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        // console.log('event--->>>', event);
        if (event instanceof HttpResponse) {
          if (environment.apimKey !== '') {
            if (request.url.includes('address-lookup')) {
              event = event.clone({
                headers: event.headers,
                body: JSON.parse(event.body)
              });
            } else if (this.allApimUrls(request) && environment.payload_encrypt === 'true') {
              event = event.clone({
                headers: event.headers,
                // TODO temporarily disable response decode Reason cannot get the cookie from header after calling AZF
                // body: this.helperService.aesDecode(event.body),
                body: JSON.parse(event.body)
              });
            } else {
              event = event.clone({
                headers: event.headers,
                body: event.body
              });
            }
          } else {
            event = event.clone({
              headers: event.headers,
              body: event.body
            });
          }
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorEMsg = error.error;

        if (environment.apimKey !== '') {
          if (this.allApimUrls(request)) {
            if (environment.payload_encrypt === 'true') {
              console.log(errorEMsg);
              errorEMsg = JSON.parse(errorEMsg);
            }
          }
        }

        switch (error.status) {
          case 401:
            // authentication error
            // logout
            const authDialog = this.modal.open(ModalComponent, {
              size: 'sm',
              centered: true,
              backdrop: 'static',
              keyboard: false
            });
            authDialog.componentInstance.data = {
              title: 'Authentication Error',
              closeBtn: true,
              content: 'Session timed out, please log in again',
              buttons: [
                {
                  text: 'Log in',
                  class: 'btn-primary',
                  value: 'ok'
                }
              ]
            };

            authDialog.closed.pipe(take(1)).subscribe(() => {
              sessionStorage.clear();
              this.router.navigate(['login']);
            });
            break;

          case 403:
            // permission & routing
            const accessDialog = this.modal.open(ModalComponent, {
              size: 'sm',
              centered: true,
              backdrop: 'static',
              keyboard: false
            });
            accessDialog.componentInstance.data = {
              title: 'Access Error',
              closeBtn: true,
              content: 'Access Denied',
              buttons: [
                {
                  text: 'Log in',
                  class: 'btn-primary',
                  value: 'ok'
                }
              ]
            };

            accessDialog.closed.pipe(take(1)).subscribe(() => {
              sessionStorage.clear();
              this.router.navigate(['login']);
            });
            break;

          case 422:
            const errors =
              typeof error.error === 'string' ? JSON.parse(error.error).errors : error.error.errors;
            const errObj: { message: string; title?: string; code?: string; isRedirect?: boolean } =
              errors[0];

            if (errObj.isRedirect) {
              this.modal.dismissAll();
              sessionStorage.setItem(
                'errorRedirection',
                JSON.stringify({ message: errObj.message, errorCode: errObj.code })
              );
              this.router.navigate([`payment-sms-journey/error`]);
            }

            if (errObj.code === 'OTP_MAX_ATTEMPT' && !errObj.isRedirect) {
              this.openErrorModal(errObj.title || 'Error', errObj.message);
            }

            this.notificationService.error(
              this.parseAPIErrorMessage(errorEMsg),
              errObj.title || 'Error'
            );
            break;

          case 500:
            // internal server error
            this.notificationService.error(
              this.parseAPIErrorMessage(errorEMsg) || error?.error.message,
              'Error'
            );
            break;

          default:
            // Other HTTP Codes
            this.notificationService.error(
              'The Portal has encountered an error. Please log back in to continue.',
              'Application Error'
            );
            break;
        }
        return throwError(error);
      })
    );
  }

  private allApimUrls(request: any): boolean {
    const apimUrls = [environment.api, environment.authBaseURL, environment.basiq.apiBaseUrl];
    return apimUrls.some(url => request.url.includes(url));
  }

  private parseAPIErrorMessage(data: GenericListResponse<any, ErrorResponse>): string {
    let errMsg = '';

    if (data.errors) {
      for (const apiData of data.errors) {
        errMsg += `<p class="mb-1">${apiData.message}</p>`;
      }
    }

    return errMsg;
  }

  private openErrorModal(title: string, message: string): void {
    const modalRef = this.modal.open(ModalComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.data = {
      uilIconClass: 'uil-exclamation-triangle',
      title,
      content: message,
      buttons: [
        {
          text: 'Acknowledge',
          class: 'btn-primary w-230 ml-1',
          value: 'true'
        }
      ]
    };

    modalRef.closed.pipe(take(1)).subscribe(() => {
      const pendingPlanRouteUrl = 'payment-plan-summary/pending';

      if (this.router.url !== pendingPlanRouteUrl) {
        this.router.navigate([pendingPlanRouteUrl]);
      }
    });
  }
}
