import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericListResponse } from '@app/core/models';
import { ApiUrlService } from '@app/core/services/api-url.service';
import { ImageService } from '@app/core/services/image/image.service';
import { environment } from '@environments/environment';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetProposalTemplateResponse } from '../../../templates/models/get-proposal-template.response';
import { PreviewProposalRequestPayload } from '../../../templates/models/preview-proposal-payload.interface';
import { SaveProposalTemplateRequest } from '../../../templates/models/save-proposal-template.request';

@Injectable({
  providedIn: 'root'
})
export class ProposalTemplateDataService {
  constructor(
    private httpClient: HttpClient,
    private apiUrlService: ApiUrlService,
    private imageService: ImageService
  ) {}

  getProposalTemplate$(config: { showLoadingBar: boolean }): Observable<GenericListResponse<GetProposalTemplateResponse, any>> {
    return this.httpClient.get<GenericListResponse<GetProposalTemplateResponse, any>>(
      `${this.apiUrlService.getProposalTemplate}`, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, !config.showLoadingBar)
      }
    );
  }

  saveProposalTemplate(body: SaveProposalTemplateRequest): Observable<any> {
    // TO DO: Need to ask BE to remap response to handle text sections
    return this.httpClient.post<any>(`${this.apiUrlService.postSaveProposalTemplate}`, body);
  }

  previewProposal(payload: PreviewProposalRequestPayload): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrlService.previewProposal}`, payload).pipe(
      map(response => {
        return response.base64PdfString;
      })
    );
  }

  enquireForPremium(): Observable<{
    message: string;
  }> {
    return this.httpClient
      .get<GenericListResponse<{ message: string }, any>>(
        `${this.apiUrlService.enquireProposalTemplate}`
      )
      .pipe(
        map(response => {
          return {
            message: response.data[0].message
          };
        })
      );
  }

  private getDefaultProposalProviderLogo$(): Observable<string> {
    return this.imageService.getImageAsBase64(
      `assets/images/${environment.client}/default-proposal-logo.png`
    );
  }
}

export interface SaveTemplateProposalResponse {
  templateProposalId: number;
  message: string;
}
