import { CommonModule, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';

export interface ToggleSwitchConfig {
  label: string;
  labelPosition: 'before' | 'after';
  isDynamicContent: boolean;
  size?: 'sm' | 'normal';
}

@Component({
  selector: 'sliqpay-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [MatSlideToggleModule, FormsModule, NgIf, CommonModule]
})
export class ToggleSwitchComponent implements ControlValueAccessor {
  @Input() config: ToggleSwitchConfig | null = {
    label: '',
    labelPosition: 'after',
    isDynamicContent: false
  };
  @Input() hideSwitch = false;
  @Output() checkChange = new EventEmitter();

  value = false;
  disabled = false;

  onChange!: (value: boolean) => void;
  onTouch!: () => void;

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onCheckedChange(change: MatSlideToggleChange): void {
    this.onChange(change.checked);
    this.checkChange.emit(change.checked);
  }
}
