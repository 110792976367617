import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { PaymentPlansColumn } from '@app/payments/models/payment-plans-column.model';

export const MissedPaymentsTableColumns: PaymentPlansColumn[] = [
  {
    sortKey: 'Patient ID',
    name: 'Patient ID',
    customClass: 'col-narrow'
  },
  {
    sortKey: 'DDR',
    name: 'DDR No.'
  },
  {
    sortKey: 'Patient Name',
    name: 'Patient Name'
  },
  {
    sortKey: 'Responsible Party',
    name: 'Responsible Party'
  },
  {
    sortKey: 'Original Plan Total',
    name: 'Original Plan Total',
    customClass: 'col-narrow'
  },
  {
    sortKey: 'Paid To Date',
    name: 'Provider Closing Balance',
    customClass: 'col-narrow'
  },
  {
    sortKey: 'Total Outstanding Amount',
    name: 'Total Sent',
    visible: new UserSettingsService().isPromptPayEnabled(),
    tooltip: `Max. of ${new UserSettingsService().getPromptPaySmsLimit()} SMS’s per Plan/Mth, this will reset on the 1st of each month`
  },
  {
    sortKey: 'Total Outstanding Amount',
    name: 'Total Overdue Amount',
    customClass: 'col-narrow'
  },
  {
    sortKey: 'custrecord_mfa_ddr_provider_comments',
    name: 'Comments',
    customClass: 'col-wide'
  },
  {
    sortKey: 'custrecord_ddr_rpcompliance_failed',
    name: 'Sent Invoice',
    visible: new UserSettingsService().isPromptPayEnabled()
  },
  {
    name: 'Transfer to APCA'
  },
  {
    name: ''
  }
];
