import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const authToken = sessionStorage.getItem('auth');
    const userObj = sessionStorage.getItem('user');

    if (!authToken || !userObj) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }
}
