import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { ProviderConfigFeature } from './provider-config-feature.enum';

@Directive({
  selector: '[sliqpayProviderConfigFeatureFlag]'
})
export class ProviderConfigFeatureFlagDirective implements OnInit {
  @Input() sliqpayProviderConfigFeatureFlag!: ProviderConfigFeature;
  @Input() preserveLayout = false;

  constructor(private userSettingsService: UserSettingsService, private elRef: ElementRef) {}

  ngOnInit(): void {
    if (!this.isFeatureEnabled()) {
      if (this.preserveLayout) {
        this.elRef.nativeElement.style.visibility = 'hidden';
      } else {
        this.elRef.nativeElement.style.display = 'none';
      }
    }
  }

  private isFeatureEnabled(): boolean {
    switch (this.sliqpayProviderConfigFeatureFlag) {
      case ProviderConfigFeature.TREATMENT_PROPOSAL:
        return this.userSettingsService.isTreatmentProposalEnabled();
      case ProviderConfigFeature.SMILE_TRACKER:
        return this.userSettingsService.isSmileTrackerEnabled();
      case ProviderConfigFeature.CONNECT:
        return this.userSettingsService.isConnectEnabled();
      case ProviderConfigFeature.PAY_IN_FULL:
        return this.userSettingsService.isPayInFullEnabled();
      case ProviderConfigFeature.PLAN_ASSISTANT:
        return this.userSettingsService.isPaymentPlanAssistantEnabled();
      case ProviderConfigFeature.PROMPT_PAY:
        return this.userSettingsService.isPromptPayEnabled();
      case ProviderConfigFeature.PAY_DEPOSIT_TO:
        return this.userSettingsService.isPayDepositToEnabled();
      case ProviderConfigFeature.CREATE_PLAN:
        return this.userSettingsService.isCreatePlanEnabled();
      case ProviderConfigFeature.PROPOSAL_MODULE:
        return this.userSettingsService.isProposalModuleEnabled();
      default:
        return false;
    }
  }
}
