import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'sliqpay-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit {
  @Input() config: any;
  @Output() queryChange = new EventEmitter();

  constructor() {
    this.config = {
      placeholder: 'Keyword',
      label: ''
    };
  }

  ngOnInit(): void {
  }

  onKeyup(event: any): void {
    this.queryChange.emit(event.target?.value);
  }
}
