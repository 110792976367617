<div class="row">
  <div class="col-12">
    <form class="form-group search-field-group">
      <label *ngIf="config?.label" class="text-truncate w-100">{{ config?.label }}</label>
      <div class="search-field-group">
        <i class="uil uil-search"></i>
        <input
          type="text"
          class="form-control"
          name="query"
          [placeholder]="config?.placeholder"
          (keyup)="onKeyup($event)"
        />
      </div>
    </form>
  </div>
</div>
