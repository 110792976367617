export enum ProviderConfigFeature {
  TREATMENT_PROPOSAL = 'treatment-proposal',
  SMILE_TRACKER = 'smile-tracker',
  CONNECT = 'connect',
  PAY_IN_FULL = 'pay-in-full',
  PLAN_ASSISTANT = 'plan-assistant',
  PROMPT_PAY = 'prompt-pay',
  PAY_DEPOSIT_TO = 'pay-deposit-to',
  CREATE_PLAN = 'create-plan',
  PROPOSAL_MODULE = 'proposal-module'
}
