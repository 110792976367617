import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import { DepositPaymentPreferenceOrder } from '@app/core/constants/deposit.const';
import { PayInFullPaymentPreferenceOrder } from '@app/core/constants/pay-in-full.const';
import { DepositPayee } from '@app/core/enums/deposit.enum';
import { PayInFullPaymentPreference } from '@app/core/enums/pay-in-full.enum';
import { ProviderType } from '@app/core/enums/provider-type.enum';
import { OrderedTextValuePair, TextValuePair } from '@app/core/models/common/form-fields.interface';
import {
  BnplSettings,
  ConnectServices,
  ConnectSettings,
  GeneralSettings,
  PlusSettings,
  UserSettings
} from '../../models/payment/user-settings.interface';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  private currentPlanSettings: WritableSignal<UserSettings | null> = signal(null);
  private currentProviderSettings: WritableSignal<UserSettings | null> = signal(
    JSON.parse(sessionStorage.getItem('user') as string)?.settings
  );
  private getSettings = computed(() => {
    return this.currentPlanSettings() || this.getCurrentProviderSettings();
  });

  setCurrentPlanSettings(settings: UserSettings | null): void {
    this.currentPlanSettings.set(settings);
  }

  setCurrentProviderSettings(settings: UserSettings | null): void {
    this.currentProviderSettings.set(settings);
  }

  getProviderName(): string {
    return JSON.parse(sessionStorage.getItem('user') as string)?.name;
  }

  getCurrentProviderSettings(): UserSettings {
    return this.currentProviderSettings() as UserSettings;
  }

  getBnplSettings(): BnplSettings {
    return this.getSettings()?.bnplSettings;
  }

  getConnectServices(): ConnectServices {
    return this.getSettings()?.connectServices;
  }

  getConnectSettings(): ConnectSettings {
    return this.getSettings()?.connectSettings;
  }

  getPlusSettings(): PlusSettings {
    return this.getSettings()?.plusSettings;
  }

  getGeneralSettings(): GeneralSettings {
    return this.getSettings()?.generalSettings;
  }

  isBnplEnabled(): boolean {
    return this.getBnplSettings()?.enablePlanType;
  }

  isPlusEnabled(): boolean {
    return this.getPlusSettings()?.enablePlanType;
  }

  isConnectEnabled(): boolean {
    return this.getConnectSettings()?.enablePlanType;
  }

  isProactiveConnectEnabled(): boolean {
    return this.getConnectSettings()?.enableProactiveConnect;
  }

  isProactiveConnectOnlyProvider(): boolean {
    return !this.isPlusEnabled() && this.isProactiveConnectEnabled();
  }

  isPlusOnlyProvider(): boolean {
    return (
      !this.isBnplEnabled() &&
      this.isPlusEnabled() &&
      !this.isConnectEnabled() &&
      !this.isProactiveConnectEnabled()
    );
  }

  isPlusAndConnectReactiveOnlyProvider(): boolean {
    return (
      !this.isBnplEnabled() &&
      this.isPlusEnabled() &&
      this.isConnectEnabled() &&
      !this.isProactiveConnectEnabled()
    );
  }

  isPlusAndConnectProactiveOnlyProvider(): boolean {
    return (
      !this.isBnplEnabled() &&
      this.isPlusEnabled() &&
      this.isConnectEnabled() &&
      this.isProactiveConnectEnabled()
    );
  }

  isOtpEnabled(): boolean {
    return this.getConnectServices()?.enableOtpConnect;
  }

  isComplianceEnabled(): boolean {
    return this.getConnectServices()?.enableComplianceConnect;
  }

  isKycEnabled(): boolean {
    return this.getConnectServices()?.enableKYCConnect;
  }

  isRpEntryConnectEnabled(): boolean {
    return this.getConnectServices()?.enableRpEntryConnect;
  }

  isPaymentPlanAssistantEnabled(): boolean {
    return this.getSettings()?.features.paymentPlanAssistant;
  }

  isSmsJourneyConnectEnabled(): boolean {
    return this.getConnectServices().enableSmsJourneyConnect;
  }

  isPdfPrintConnectEnabled(): boolean {
    return this.getConnectServices().enablePdfPrintConnect;
  }

  isTreatmentProposalEnabled(): boolean {
    return this.getSettings()?.features.treatmentProposal;
  }

  isSmileTrackerEnabled(): boolean {
    return this.getSettings()?.features.enableSmileTracker;
  }

  getMaxTemplate(): string {
    return this.getSettings()?.features.maxTemplate;
  }

  getFpdPreference(): TextValuePair | null {
    const value = this.getGeneralSettings().firstPaymentDatePreference;

    return Array.isArray(value) ? null : value;
  }

  getDefaultDiscSetting(): TextValuePair | null {
    const value = this.getSettings()?.features.treatmentProposalDiscountSettings;

    return Array.isArray(value) ? null : value;
  }

  isBasiqEnabled(): boolean {
    return this.getConnectServices().enableBasiq;
  }

  isPayInFullEnabled(): boolean {
    return this.getSettings()?.features.enablePayInFull;
  }

  isPromptPayEnabled(): boolean {
    return this.getSettings()?.features.promptPay;
  }

  getPromptPaySmsLimit(): string {
    return this.getSettings()?.features.promptPaySmsLimit;
  }

  isPayDepositToEnabled(): boolean {
    return this.getSettings().features.enableDepositConfig;
  }

  getProviderType(): ProviderType {
    return JSON.parse(sessionStorage.getItem('user') as string)?.clinic_type;
  }

  getProviderConsent(): string {
    return this.getGeneralSettings().rpConsent || '';
  }

  isViewDetailsConnectEnabled(): boolean {
    return this.getConnectServices()?.enableViewDetailsConnect;
  }

  isQrCodeEnabled(): boolean {
    return this.getSettings()?.features.enableQrCode;
  }

  isPifOrtho15kAssured(): boolean {
    return this.getSettings()?.features.orthoMaxAssuredPIF;
  }

  isConnectOrtho15kAssured(): boolean {
    return this.getConnectSettings()?.orthoMaxAssuredConnect;
  }

  isPlusOrtho15kAssured(): boolean {
    return this.getPlusSettings()?.orthoMaxAssuredPlus;
  }

  getPifPaymentOptions(): OrderedTextValuePair[] {
    const rawList = this.getSettings().features.payInFullOwner;
    if (!rawList) {
      return [];
    }
    return this.transformPifOwnerList(Array.isArray(rawList) ? rawList : [rawList]);
  }

  getDefaultPifPaymentOption(): PayInFullPaymentPreference | null {
    const pifOptions = this.getPifPaymentOptions();
    return pifOptions.length > 0 ? (pifOptions[0].value as PayInFullPaymentPreference) : null;
  }

  getDepositPaymentPreferenceOptions(): OrderedTextValuePair[] {
    const rawList = this.getGeneralSettings().depositOwner;
    if (!rawList) {
      return [];
    }
    return this.transformDepositOwnerList(Array.isArray(rawList) ? rawList : [rawList]);
  }

  getDefaultDepositPaymentOption(): DepositPayee | null {
    const depositPayeeOptions = this.getDepositPaymentPreferenceOptions();
    return depositPayeeOptions.length > 0 ? (depositPayeeOptions[0].value as DepositPayee) : null;
  }

  isPifOnlyEnabled(): boolean {
    return this.getSettings().features.payInFullOnly;
  }

  isCreatePlanEnabled(): boolean {
    return this.getSettings().generalSettings.enableCreatePlan;
  }

  isProposalModuleEnabled(): boolean {
    return this.getSettings().features.proposalModule;
  }

  isOtpSmsEnabled(): boolean {
    return this.getSettings().features.enableOtpSMS;
  }

  isShowEmailFieldEnabled(): boolean {
    return this.getSettings().features.showEmailField;
  }

  getProviderTier(): TextValuePair {
    return this.getSettings()?.generalSettings.providerTier;
  }

  private transformPifOwnerList(textValuePairs: TextValuePair[]): OrderedTextValuePair[] {
    return textValuePairs
      .map(tvp => ({
        ...tvp,
        order: PayInFullPaymentPreferenceOrder.get(tvp.value as PayInFullPaymentPreference) || 99
      }))
      .sort((a, b) => a.order - b.order);
  }

  private transformDepositOwnerList(textValuePairs: TextValuePair[]): OrderedTextValuePair[] {
    return textValuePairs
      .map(tvp => ({
        ...tvp,
        order: DepositPaymentPreferenceOrder.get(tvp.value as DepositPayee) || 99
      }))
      .sort((a, b) => a.order - b.order);
  }
}
