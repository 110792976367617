import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  Signal,
  ViewChildren
} from '@angular/core';
import { ProviderConfigFeature } from '@app/core/directives/provider-config-feature-flag/provider-config-feature.enum';
import { NgVarDirective } from '@app/core/directives/var/ng-var.directive';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { PaymentMissedItemResponse } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import {
  CommentModalComponent,
  CommentPropertyName
} from '@app/payments/modals/comment-modal/comment-modal.component';
import { PaymentPlansColumn } from '@app/payments/models/payment-plans-column.model';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { DateSearchRequest, SearchRequest } from '@app/shared/interfaces/search-request.interface';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MissedPaymentsService } from '../../services/missed-payments.service';
import { MissedPaymentsTableColumns } from './missed-payments-columns.data';
import { MissedPaymentsListService } from './missed-payments-list.service';

@Component({
  selector: 'sliqpay-missed-payments-list',
  templateUrl: './missed-payments-list.component.html',
  styleUrls: ['./missed-payments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MissedPaymentsListService]
})
export class MissedPaymentsListComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'missed_payment_list';

  ModuleType = ModuleType;
  ProviderConfigFeature = ProviderConfigFeature;
  tableColumns: PaymentPlansColumn[] = [];

  noOfSMS = '';
  isLoading!: Signal<boolean>;
  page = 0;
  itemPerPage = environment.itemPerPage;
  searchData: SearchRequest = {};
  dateSearchData: DateSearchRequest = {};

  paymentPlan$ = new BehaviorSubject<PaymentMissedItemResponse[] | null>(null);
  paymentPlanFiltered$ = new BehaviorSubject<PaymentMissedItemResponse[] | null>(null);
  @ViewChildren(NgVarDirective) headers: QueryList<NgVarDirective> | any[] = [];
  // Config
  createPlanBtnConfig$!: Observable<AppButton>;
  escalateBtnConfig$!: Observable<AppButton>;
  sendInvoiceBtnConfig$!: Observable<AppButton>;
  searchWidgetConfig$!: Observable<SearchConfig[]>;

  private unsubscribe$ = new Subject<boolean>();

  constructor(
    public helperService: HelperService,
    public userSettingsService: UserSettingsService,
    public missedPaymentsListService: MissedPaymentsListService,
    private missedPaymentsService: MissedPaymentsService,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.tableColumns = MissedPaymentsTableColumns.filter((col) => col.visible ?? true);
    this.isLoading = this.missedPaymentsService.isLoadingMissedPayments();

    this.setConfig();
    this.getPaymentMissed();

    this.noOfSMS =
      'Max. of ' +
      this.userSettingsService.getPromptPaySmsLimit() +
      ' SMS’s per Plan/Mth, this will reset on the 1st of each month';
  }

  ngOnDestroy(): void {
    this.missedPaymentsService.resetMissedPaymentsList();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPaymentMissed() {
    this.missedPaymentsService
      .getAllMissedPayments$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(mergedMissedPayments => {
        this.paymentPlan$.next(mergedMissedPayments);
        this.paymentPlanFiltered$.next(mergedMissedPayments);
      });
  }

  invoiceSent(plan: PaymentMissedItemResponse) {
    this.missedPaymentsService
      .fetchMissedPayments$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  gotoDetailsScreen(plan: PaymentMissedItemResponse) {
    this.missedPaymentsService.gotoMissedPaymentDetailsScreen(plan['DDR Internal ID'], true);
  }

  onSort({ column, direction, type }: any) {
    this.paymentPlanFiltered$.next(
      this.helperService.onSort(column, direction, this.paymentPlan$.value, type)
    );
  }

  searchFilter(event: SearchRequest) {
    this.page = 0;
    this.searchData = event;
  }

  onDateFilterChange(event: { from: Date; to: Date }): void {
    this.dateSearchData = {
      from: event.from,
      to: event.to,
      searchColumn: 'Latest In Arrears Date'
    };
  }

  comment(plan: PaymentMissedItemResponse): void {
    const modal = this.modal.open(CommentModalComponent, { centered: true });
    modal.componentInstance.id = plan['DDR Internal ID'];
    modal.componentInstance.propertyName = CommentPropertyName.DDR_PLANS;
    modal.componentInstance.commentValue = plan.custrecord_mfa_ddr_provider_comments;
    modal.closed
      .pipe(
        filter(d => !!d),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        this.planChanged({
          ...plan,
          custrecord_mfa_ddr_provider_comments: data.custrecord_mfa_ddr_provider_comments
        });
      });
  }

  planChanged(plan: PaymentMissedItemResponse) {
    if (this.paymentPlanFiltered$.value) {
      const changedList = this.paymentPlanFiltered$.value.map(p => {
        return p['DDR Internal ID'] === plan['DDR Internal ID'] ? plan : p;
      });
      this.paymentPlanFiltered$.next(changedList);
    }
  }

  onCommentActionChange(commentEvent: {
    plan: PaymentMissedItemResponse;
    commentValue: string;
  }): void {
    this.planChanged({
      ...commentEvent.plan,
      custrecord_mfa_ddr_provider_comments: commentEvent.commentValue
    });
  }

  private setConfig(): void {
    this.createPlanBtnConfig$ = this.missedPaymentsListService.getCreatePlanBtnConfig();
    this.searchWidgetConfig$ = this.missedPaymentsListService.getSearchWidgetConfig();
  }
}
