import { Injectable } from '@angular/core';
import { PaymentPlanDepositStatusLabel } from '@app/core/constants/deposit.const';
import { PayInFullPaymentStatusLabel } from '@app/core/constants/pay-in-full.const';
import { PlanListDataService } from '@app/core/data-services/plan-list/plan-list-data.service';
import { DepositStatus } from '@app/core/enums/deposit.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { PaymentPlanItemResponse } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class PaymentPlansService {
  private activePlansSubject = new BehaviorSubject<PaymentPlanItemResponse[]>([]);

  constructor(
    private planListDataService: PlanListDataService,
    private helperService: HelperService
  ) {}

  initAllActivePlans$(options: { includeCompletedPlans: boolean }): Observable<any> {
    return this.planListDataService
      .getPaymentPlanSummary$(options)
      .pipe(
        map(res => (this.helperService.checkAPIResponse(res) ? res.data : [])),
        tap(activePlans => this.setActivePlans(activePlans))
      );
  }

  setActivePlans(activePlans: PaymentPlanItemResponse[]): void {
    const transformedList = activePlans.map(plan => this.transformAllPlansItem(plan));
    this.activePlansSubject.next(transformedList);
  }

  getAllActivePlans$(): Observable<PaymentPlanItemResponse[]> {
    return this.activePlansSubject.asObservable();
  }

  getAllActivePlans(): PaymentPlanItemResponse[] {
    return this.activePlansSubject.getValue();
  }

  updateActivePlanStateById(ddrId: string, data: Partial<PaymentPlanItemResponse>): void {
    const snapshot = [...this.getAllActivePlans()];
    const ddrIndex = snapshot.findIndex(ddr => ddr.ddrId === ddrId);

    snapshot[ddrIndex] = this.transformAllPlansItem({ ...snapshot[ddrIndex], ...data });
    this.activePlansSubject.next(snapshot);
  }

  private transformAllPlansItem(plan: PaymentPlanItemResponse): PaymentPlanItemResponse {
    return {
      ...plan,
      $$providerResidualSortingValue: +plan.providerResidual,
      $$depositOrPifStatusLabel: this.getPaymentStatusLabelByType(
        plan.ddr_plan_type as PlanType,
        plan.deposit_status
      ),
      $$isDepositPaid:
        plan.deposit_status === DepositStatus.CLEARED || plan.deposit_status === DepositStatus.PAID
    };
  }

  private getPaymentStatusLabelByType(type: PlanType, paymentStatusId: string): string {
    if (type === PlanType.PayInFull) {
      return this.getPayInFullStatusByStatusId(paymentStatusId);
    } else {
      return this.getDepositStatusByStatusId(paymentStatusId as DepositStatus);
    }
  }

  private getDepositStatusByStatusId(statusId: DepositStatus): string {
    const depositStatusToDisplay = [
      DepositStatus.PENDING,
      DepositStatus.PRACTICE_TO_PROCESS,
      DepositStatus.CLEARED,
      DepositStatus.PAID
    ];

    return depositStatusToDisplay.includes(statusId)
      ? PaymentPlanDepositStatusLabel.get(statusId) || ''
      : '';
  }

  private getPayInFullStatusByStatusId(statusId: string): string {
    return PayInFullPaymentStatusLabel.get(statusId) || '';
  }
}
