import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  ErrorResponse,
  GenericListResponse,
  TemplateItemRequest,
  TemplateItemResponse
} from '@core/models';
import { ApiUrlService } from '@core/services/api-url.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesDataService {
  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService) {}

  getAllTemplates$(): Observable<GenericListResponse<TemplateItemResponse, ErrorResponse>> {
    return this.httpClient.get<GenericListResponse<TemplateItemResponse, ErrorResponse>>(
      `${this.apiUrlService.paymentPlanTemplateUrl}`
    );
  }

  createPaymentPlanTemplate(
    body: TemplateItemRequest
  ): Observable<GenericListResponse<TemplateItemResponse, ErrorResponse>> {
    return this.httpClient.post<GenericListResponse<TemplateItemResponse, ErrorResponse>>(
      `${this.apiUrlService.paymentPlanTemplateUrl}`,
      body
    );
  }

  updatePaymentPlanTemplate(
    body: TemplateItemRequest,
    id: number | string
  ): Observable<GenericListResponse<TemplateItemResponse, ErrorResponse>> {
    return this.httpClient
      .put<GenericListResponse<TemplateItemResponse, ErrorResponse>>(
        `${this.apiUrlService.paymentPlanTemplateUrl}/${id}`,
        body
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('EditSupportCase unsuccessful', error);
          return throwError(error);
        })
      );
  }

  deletePaymentPlanTemplate(
    id: number
  ): Observable<GenericListResponse<{ field: string; message: string }, ErrorResponse>> {
    return this.httpClient
      .delete<GenericListResponse<{ field: string; message: string }, ErrorResponse>>(
        `${this.apiUrlService.paymentPlanTemplateUrl}/${id}`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('createSupportCase unsuccessful', error);
          return throwError(error);
        })
      );
  }

  fetch(id: number): Observable<any> {
    return this.httpClient.get<TemplateItemResponse>(
      this.apiUrlService.paymentPlanTemplateUrl + `/${id}`
    );
  }
}
