import { UserSettingsService } from "@app/core/services/user-settings/user-settings.service";
import { PaymentPlansColumn } from "../models/payment-plans-column.model";

export const PendingPlansTableColumns: PaymentPlansColumn[] = [
  {
    name: 'Request Type',
    sortKey: 'custrecord_module_type_label'
  },
  {
    name: 'Patient ID',
    sortKey: 'json_transformed.practice_patient_id_no'
  },
  {
    name: 'Patient Name',
    sortKey: 'patient_name'
  },
  {
    name: 'Plan ID',
    sortKey: 'id'
  },
  {
    name: 'Created Date/Time',
    sortKey: 'created_at',
    type: 'dateTime'
  },
  {
    name: 'Plan Start Date',
    sortKey: '$$startDate',
    type: 'date'
  },
  {
    name: 'Deposit Release Date',
    sortKey: 'deposit_expiry',
    type: 'dateTime',
    visible: new UserSettingsService().isTreatmentProposalEnabled(),
    dateTimeFormat: 'dd/M/yyyy h:m:s a'
  },
  {
    name: 'Date SMS Sent',
    sortKey: 'custrecord_ddrip_last_sms_date_time',
    type: 'dateTime',
    dateTimeFormat: 'dd/MM/yyyy h:m:s a'
  },
  {
    name: 'Progress',
    sortKey: 'plan_progress_percentage',
    visible: new UserSettingsService().isSmileTrackerEnabled()
  },
  {
    name: 'Comments',
    sortKey: 'custrecord_unsubmitted_comment'
  },
  {
    name: '#Sent',
    sortKey: 'smsCountNumber'
  }
];
