import { Injectable } from '@angular/core';
import { TierPermission } from '../../models/auth/tier-permission.interface';

@Injectable({
  providedIn: 'root'
})
export class TierPermissionService {
  getPermission(): TierPermission {
    return JSON.parse(sessionStorage.getItem('user') as string)?.tier_permissions;
  }

  getAmendmentIncreasePermission(): boolean {
    return this.getPermission().custrecord_tier_amend_increase;
  }
}
