import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

export type SortColumn = keyof any | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' };

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
  type: string;
  dateTimeFormat?: string;
}

@Directive({
  selector: 'th[sliqpaySortable]'
})
export class ThSortableDirective {
  @Input() sliqpaySortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Input() type: string = '';
  @Input() dateTimeFormat?: string;
  @Output() sort = new EventEmitter<SortEvent>();

  @HostBinding('class')
  get hostClass(): string {
    if (this.direction === 'asc') {
      return 'asc';
    } else if (this.direction === 'desc') {
      return 'desc';
    } else if (this.direction === '') {
      return 'cursor-pointer';
    }
    return '';
  }

  @HostListener('click')
  onClick(): void {
    if (!this.sliqpaySortable) {
      return;
    }

    this.direction = rotate[this.direction];
    this.sort.emit({
      column: this.sliqpaySortable,
      direction: this.direction,
      type: this.type,
      dateTimeFormat: this.dateTimeFormat
    });
  }
}
