import { UserSettingsService } from "@app/core/services/user-settings/user-settings.service";
import { PaymentPlansColumn } from "../models/payment-plans-column.model";

export const ApprovalQueueTableColumns: PaymentPlansColumn[] = [
  {
    sortKey: 'ddrId',
    name: 'DDR No.'
  },
  {
    sortKey: 'ddr_plan_type_label',
    name: 'Plan Type'
  },
  {
    sortKey: 'patient_name',
    name: 'Patient Name'
  },
  {
    sortKey: 'practice_patient_id_no',
    name: 'Patient ID'
  },
  {
    sortKey: 'responsible_party_name',
    name: 'Responsible Party',
    customClass: 'w-15'
  },
  {
    sortKey: 'total',
    name: 'Amount',
    type: 'number'
  },
  {
    sortKey: '$$depositStatusLabel',
    name: 'Deposit Status',
    visible: new UserSettingsService().isTreatmentProposalEnabled()
  },
  {
    sortKey: 'deposit_expiry',
    name: 'Deposit Release Date',
    visible: new UserSettingsService().isTreatmentProposalEnabled(),
    type: 'datetime'
  },
  {
    sortKey: 'ddr_status_label',
    name: 'Plan Status'
  },
  {
    sortKey: 'custrecord_ddr_rpcompliance_failed',
    name: 'Failed Criteria',
    customClass: 'w-15'
  },
  {
    name: 'Approve/Ineligible Payment Plan'
  }
];
