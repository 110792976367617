import { Injectable } from '@angular/core';
import { UpdatePlanFieldCodes } from '@app/core/constants/payment-plan.const';
import { PaymentPlanDataService } from '@app/core/data-services/payment-plan/payment-plan-data.service';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { PayInFullPaymentStatus } from '@app/core/enums/pay-in-full.enum';
import { PlanStatus } from '@app/core/enums/plan-status.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { ProviderTier } from '@app/core/enums/provider-tier.enum';
import { PaymentPlanItemResponse } from '@app/core/models';
import { ConfigService } from '@app/core/services/config/config.service';
import { HelperService } from '@app/core/services/helper.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { PaymentPlansService } from '@app/payments/services/payment-plans.service';
import {
  AppThreeDotActionMenu,
  MenuItem
} from '@app/shared/interfaces/three-dot-action-menu.interface';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export enum AllPlansActions {
  Comment = '1',
  EditPatientId = '2',
  RequestPlanChanges = '3',
  Amend = '3.1',
  Pause = '3.2',
  Resume = '3.3',
  Transfer = '3.4',
  General = '3.5',
  Preview = '4',
  PreviewSchedule = '4.1',
  PreviewAgreement = '4.2',
  Download = '5',
  DownloadSchedule = '5.1',
  DownloadAgreement = '5.2',
  SendAppLink = '6',
  ViewMissedPaymentDetails = '7',
  PaymentCollected = '8'
}

@Injectable()
export class PaymentsActionMenuService {
  private CONFIG_KEY = 'all_plans_action_menu';

  APPROVED_STATUS = [PlanStatus.Approved];
  ALLOW_TO_PAUSE_STATUS = [PlanStatus.Approved, PlanStatus.Hardship, PlanStatus.SuspendedRp];
  PAUSED_STATUS = [PlanStatus.SuspendedProvider, PlanStatus.SuspendedProviderAndRp];

  constructor(
    private configService: ConfigService,
    private userSettingsService: UserSettingsService,
    private paymentDataService: PaymentPlanDataService,
    private helperService: HelperService,
    private paymentPlansService: PaymentPlansService
  ) {}

  getActionsConfig(plan: PaymentPlanItemResponse | null): Observable<AppThreeDotActionMenu> {
    const moduleType = plan ? plan.module_type : ModuleType.Plan;
    const config = this.getConfigByModuleType(moduleType);

    return config.pipe(
      map(data => {
        const filteredData: MenuItem[] = data.menuItems;
        const transformedItems = filteredData.reduce<MenuItem[]>((items, item) => {
          switch (item.value) {
            case AllPlansActions.Preview:
            case AllPlansActions.Download: {
              const planStatusAllowedForPreviewAndDownload = [
                PlanStatus.Approved,
                PlanStatus.Complete,
                PlanStatus.PayAtPractice
              ];
              const isNotPremiumPif =
                plan?.ddr_plan_type === PlanType.PayInFull &&
                this.userSettingsService.getProviderTier()?.value !== ProviderTier.PREMIUM;
              if (
                !planStatusAllowedForPreviewAndDownload.includes(plan?.ddr_status as PlanStatus) ||
                isNotPremiumPif
              ) {
                return items;
              }

              const transformedItem = { ...item };
              if (item.subMenu) {
                transformedItem.subMenu = {
                  ...item.subMenu,
                  menuItems: this.filterPreviewSubItemsByPlanType(
                    item.subMenu.menuItems,
                    plan?.ddr_plan_type as PlanType
                  )
                };
              }

              items.push(transformedItem);
              break;
            }
            case AllPlansActions.RequestPlanChanges: {
              if (!this.hasRequestPlanChangesAccess(plan)) {
                return items;
              }

              const transformedItem = { ...item };
              if (item.subMenu) {
                transformedItem.subMenu = {
                  ...item.subMenu,
                  menuItems: this.filterAmendmentSubItemsByPlan(
                    item.subMenu.menuItems,
                    plan as PaymentPlanItemResponse
                  )
                };
              }
              items.push(transformedItem);
              break;
            }
            case AllPlansActions.SendAppLink: {
              // maximum 3 times for SMS
              if (
                plan?.pvGiveAccess === true ||
                parseInt(plan?.rpRegisterSMSCount || '', 10) >= 3 ||
                plan?.ddr_plan_type === PlanType.PayInFull
              ) {
                return items;
              }
              items.push(item);
              break;
            }
            case AllPlansActions.ViewMissedPaymentDetails: {
              if (
                plan?.ddr_plan_type === PlanType.NonGuaranteed &&
                plan?.ddr_status === PlanStatus.Approved &&
                this.userSettingsService.isViewDetailsConnectEnabled()
              ) {
                items.push(item);
              }
              return items;
            }
            case AllPlansActions.PaymentCollected: {
              const criteria = [
                plan?.ddr_plan_type === PlanType.PayInFull,
                plan?.ddr_status === PlanStatus.PayAtPractice,
                `${plan?.deposit_status}` === PayInFullPaymentStatus.TO_BE_COLLECTED_AT_PRACTICE
              ];
              if (criteria.every(i => i)) {
                items.push(item);
              }
              return items;
            }
            default:
              items.push(item);
          }

          return items;
        }, []);

        return { menuItems: transformedItems };
      })
    );
  }

  filterAmendmentSubItemsByPlan(menuItems: MenuItem[], plan: PaymentPlanItemResponse): MenuItem[] {
    const planStatus = plan.ddr_status as PlanStatus;
    const planType = plan?.ddr_plan_type;

    if (planType === PlanType.PayInFull) {
      return menuItems.filter(item => item.value === AllPlansActions.General);
    }

    if (planType === PlanType.Bnpl) {
      return menuItems.filter(item => item.value === AllPlansActions.Amend);
    }

    // filter by plan status
    return menuItems.filter(item => {
      switch (item.value) {
        case AllPlansActions.Amend:
          return (
            this.APPROVED_STATUS.includes(planStatus) &&
            !(planType === PlanType.Guaranteed && plan?.portal_plan_type_label == 'Connect')
          );
        case AllPlansActions.Pause:
          return this.ALLOW_TO_PAUSE_STATUS.includes(planStatus);
        case AllPlansActions.Resume:
          return this.PAUSED_STATUS.includes(planStatus);
        default:
          return true;
      }
    });
  }

  filterPreviewSubItemsByPlanType(menuItems: MenuItem[], planType: PlanType): MenuItem[] {
    const items = [...menuItems];

    return items.filter(item => {
      switch (item.value) {
        case AllPlansActions.PreviewSchedule:
        case AllPlansActions.DownloadSchedule: {
          return planType !== PlanType.PayInFull;
        }
        case AllPlansActions.PreviewAgreement:
        case AllPlansActions.DownloadAgreement: {
          return this.userSettingsService.getProviderTier()?.value === ProviderTier.PREMIUM;
        }
        default:
          return true;
      }
    });
  }

  markPifAsPaidAtPractice(plan: PaymentPlanItemResponse): void {
    this.paymentDataService
      .markAsPaymentCollectedInPractice$(plan.ddrId, {
        fieldCode: UpdatePlanFieldCodes.PIF_PAYMENT_STATUS,
        custrecord_pif_payment_details: this.helperService.getObjUser().contact_id
      })
      .pipe(take(1))
      .subscribe(() => {
        this.paymentPlansService.updateActivePlanStateById(plan.ddrId, {
          deposit_status: PayInFullPaymentStatus.COLLECTED_AT_PRACTICE
        });
      });
  }

  private hasRequestPlanChangesAccess(plan: PaymentPlanItemResponse | null): boolean {
    const allowedStatusesForPlanChange = [...this.APPROVED_STATUS, ...this.PAUSED_STATUS];

    return allowedStatusesForPlanChange.includes(plan?.ddr_status.toString() as PlanStatus);
  }

  private getConfigByModuleType(moduleType: string): Observable<any> {
    return moduleType === ModuleType.Proposal
      ? this.configService.getConfigValue(this.CONFIG_KEY, 'proposal_actions')
      : this.configService.getConfigValue(this.CONFIG_KEY, 'actions');
  }
}
